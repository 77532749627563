<template>
  <div class="bg-dmr-blue">
    <div class="max-w-xl text-center text-white p-4 mx-auto">
      <p>Information on this site is intended for residents of Nova Scotia only.</p>
      <p>Tel: 902.624.1555 | Fax: 902.531.3034 | Email DMR Financial</p>
      <p>237 Edgewater Street, PO Box 539, Mahone Bay, Nova Scotia, B0J 2E0</p>
      <p>© Dale Rafuse / DMR Financial Services Inc. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>