<template>
  <div id="app" class="bg-dmr-pink min-h-screen">
    <Header />

    <div class="max-w-4xl mx-auto">
      <!-- <Header /> -->
      <div class="flex">
        <div id="desktop-sidebar" class="bg-dmr-blue text-white hidden md:flex flex-col p-4">
          <router-link
            v-for="route in $router.options.routes"
            :to="{ name: route.name }"
            :key="route.name"
          >
            <div class="flex items-center w-full p-2">
              <font-awesome-icon :icon="route.icon" class="mr-3" />
              <h3>{{route.label}}</h3>
            </div>
          </router-link>
        </div>

        <div class="w-full flex flex-col bg-white">
          <router-view class="p-8 leading-normal" />
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="scss">
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
</style>
