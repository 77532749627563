<template>
  <div class="about">
    <h1 class="text-xl font-semibold leading-relaxed text-dmr-blue">Products and Services</h1>
    <p>The following is an overview of the products we use to fulfill your financial plan.</p>

    <div v-for="category in categories" :key="category.title" class="py-2">
      <h2 class="text-lg font-semibold leading-relaxed text-dmr-blue">{{category.title}}</h2>
      <ul>
        <li v-for="product in category.products" :key="product.title">
          <span>
            <font-awesome-icon :icon="['fas','chevron-right']" class="mr-1 text-dmr-blue" />
            {{ product.title}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      categories: [
        {
          title: "Life Insurance",
          products: [
            { title: "Permanent Life Insurance" },
            { title: "Universal Life Insurance" },
            { title: "Term Life Insurance" }
          ]
        },
        {
          title: "Investment Products",
          products: [
            { title: "Segregated Fund Policies" },
            { title: "Guaranteed Investment certificates" },
            { title: "Payout Annuities" },
            { title: "RRSP and RRIF" }
          ]
        },
        {
          title: "Living Benefits",
          products: [
            { title: "Individual Health Insurance" },
            { title: "Disability Insurance" },
            { title: "Critical Illness Insurance" },
            { title: "Long Term Care Insurance" }
          ]
        },
        {
          title: "Group Benefits",
          products: [
            { title: "Health Insurance" },
            { title: "Dental Insurance" },
            { title: "Long Term Disability" },
            { title: "Life Insurance" },
            { title: "Pension Plans" },
            { title: "Group RRSP" }
          ]
        },
        {
          title: "Corporations",
          products: [
            { title: "Funding Buy/Sell Agreements" },
            { title: "Key Person Insurance" },
            { title: "Executive Retirement Compensation" },
            { title: "Corporate Investments" },
            { title: "Business Continuation" }
          ]
        },
        {
          title: "Individual Benefits",
          products: [
            { title: "Estate Planning" },
            { title: "Wealth Accumulation" },
            { title: "Tax Deferral Savings" },
            { title: "Retirement Income Planning" },
            { title: "Charitable Giving" }
          ]
        }
      ]
    };
  }
};
</script>

<style></style>
