<template>
  <div>
    <div class="bg-dmr-pink">
      <div class="max-w-4xl mx-auto bg-dmr-blue h-2"></div>
      <div class="max-w-4xl mx-auto bg-white flex justify-between items-center">
        <h1 class="font-semibold text-dmr-pink text-2xl text-center p-4">DMR Financial Services</h1>

        <div class="flex text-sm text-dmr-blue flex-col p-4">
          <p>237 Edgewater Street, Mahone Bay, NS</p>
          <p>Tel: 902.624.1555 | Fax: 902.531.3034</p>
          <p>Email: info@dmrfinancial.ca</p>
        </div>
      </div>
    </div>
    <div class="bg-dmr-beige">
      <div class="max-w-4xl mx-auto h-48">
        <img
          class="w-full h-full object-cover"
          src="https://dmrfinancial.ca/images/dmr_financial_halifax_skyline.jpg"
          alt
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>