<template>
  <div id="team">
    <h1 class="text-xl font-semibold leading-loose text-dmr-blue">Our Team</h1>

    <div class="flex flex-wrap">
      <div class="w-full md:w-2/3 md:pr-8">
        <h2 class="text-lg font-semibold leading-loose text-dmr-blue">Dale Rafuse</h2>
        <p ckass="text-sm font-light uppercase">CFP, CLU, CH.F.C</p>
        <p>Senior Financial Advisor</p>
        <p>
          Dale founded DMR Financial Services in 2001 and has been a career
          Financial Advisor since 1984. He is a graduate of Mahone Bay High
          School and Saint Mary’s University; and is a Chartered Life
          Underwriter, a Chartered Financial Consultant, and a Certified
          Financial Planner. His areas of specialization are Wealth
          Accumulation, Retirement Income, and Corporate Insurance.
        </p>
        <p>
          Dale is a Charter Director of the Bridgewater Rotary Club, and of the
          Mahone Islands Conservation Association (MICA). He is currently a
          board member of the Chester Golf Club, and the Church Memorial Park,
          Chester.
        </p>
      </div>
      <div class="w-full md:w-1/3">
        <img src="@/assets/dale_rafuse.jpg" alt />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
