<template>
  <div id="team">
    <h1 class="text-xl font-semibold leading-loose text-dmr-blue">Our Approach</h1>
    <p>
      We provide our clients with a comprehensive, 4 step approach, which has
      delivered results for over 20 years. Our success has inspired many clients
      to bring us referrals.
    </p>
    <div class="h-8"></div>
    <h1 class="text-xl font-semibold leading-loose text-dmr-blue">Our Process</h1>
    <p>
      Our process consists of 4 steps and is presented in a framework that can
      be applied effectively to many different people, from varying backgrounds,
      and for their different stages of life. We will help you create a
      financial plan, and use various financial products to fulfill that plan.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
