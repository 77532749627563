<template>
  <div class="home">
    <h1 class="text-xl font-semibold leading-loose text-dmr-blue">Welcome</h1>
    <p>
      DMR Financial Services Inc. is a dynamic company with a talented and
      knowledgeable team of professionals. Individuals, Groups, and Corporate
      clients can benefit from DMR's commitment to unparalleled customer
      service.
    </p>
    <p>
      If you are considering the services of a Financial Planner or are
      interested in discussing financial products such as Life Insurance,
      Investments, Living Benefits, Group Benefits, Individual Benefits, or
      Corporate services, we would be pleased to discuss your requirements on a
      private, no-obligation basis.
    </p>
    <div class="h-8"></div>

    <h2 class="text-lg font-semibold leading-loose text-dmr-blue">Our Vision</h2>
    <p>
      Our vision is to provide our clients with the very best advice and service
      in the financial planning business. Our goal is to maintain our position
      as advisors of choice for our clients, and for the referrals they give us.
      We will grow our practice by providing our clients with a process that
      delivers consistent results.
    </p>
    <div class="h-8"></div>
    <h2>
      <span class="text-lg font-semibold leading-loose text-dmr-blue">Our Mission</span>
      is very simple...
    </h2>
    <p>"Do or Do Not, there is no try!"</p>
    <p>- Yoda</p>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld
  }
};
</script>
